#blog-section {
  section {
    margin-bottom: 3em;

    h2 {
      display: flex;
      justify-content: space-between;

      time {
        font-size: 0.75em;
        font-weight: normal;
      }
    }

    &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }
  }

  img {
    display: block;
    width: 100%;
    max-width: 400px;
    float: right;
    margin: 0 0 2em 2em;

    & + img {
      clear: right
    }
  }

  .image-row {
    display: flex;

    img {
      flex: 1;
      margin-left: 10px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .twitter-tweet {
    float: right;
    clear: right;
  }

  .youtube-video {
    display: block;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    img {
      float: none;
      margin: 10px auto;
    }
  }
}

#blog-years {
  display: flex;

  ol, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ol {
    margin-left: 1%;
    flex: 1;
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
  }
}