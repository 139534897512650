main section {
  padding: 2%;

  @media screen and (min-width: 980px) {
    border-radius: 20px;
  }

  img {
    float: left;
    margin: 0 2% 0 0;
    width: 175px;
    height: 220px;
    border-radius: 20px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &:nth-child(odd) {
    background-color: #f5f3f5;
    text-align: right;

    img {
      float: right;
      margin: 0 0 0 2%;
    }
  }

  h2 {
    margin: 0;
    background-color: #8fe1a2;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 15px;
    min-width: 200px;
    font-weight: bold;
    text-align: center;
  }

  p {
    line-height: 1.4;
  }
}
