@charset "utf-8";

#covid-notice {
  background: #F4F212;
  padding: 1rem;
  text-align: center;
  font-size: 1.4rem;
  
  strong {
    font-weight: 700;
  }
}

#company-name {
  font-size: 140%;
  font-family: Helvetica, Arial, sans-serif;
  margin: 10px 0;
  color: #666;
  text-indent: -9999px;
  overflow: hidden;
  height: 70px;
  text-align: center;

  @media screen and (min-width: 768px) {
    body.home-page & {
      display: none;
    }
  }

  a {
    display: block;
    width: 191px;
    height: 70px;
    margin: 0 auto;
    background: url('/images/sayitslp-logo.png') no-repeat center center;
    background-size: contain;
  }

  b {
    color: #F4F212;
  }

  #slp-services {
    font-size: 100%;
    font-weight: normal;
  }
}

header {
  display: flex;
  margin-top: 10px;
  border-top: 1px solid rgba(116, 116, 116, 0.15);
  justify-content: center;

  #page-nav {
    display: flex;
    margin: 0;
    padding: 0;
    letter-spacing: 2px;
    width: 100%;
    max-width: 960px;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      margin-top: 0;
      font-size: 11px;
      letter-spacing: 1px;

      li {
        margin: 0 1.6%;
      }
    }

    li {
      flex: 0 1 auto;
      margin: 0 2%;
      padding: 0;
      list-style: none;
      display: inline-block;
      white-space: nowrap;
      text-align: center;
    }

    a {
      display: block;
      padding: 0.75em 2px 0.5em 2px;
      text-decoration: none;
      color: #3B3B3B;
      border-bottom: 3px solid transparent;
      transition: 0.2s ease-in border-bottom-color;
      text-transform: uppercase;
      font-size: 90%;

      &:hover {
        border-bottom-color: #2B2B2B;
      }
    }
  }
}

h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: url('/images/banner-background-cropped.png') no-repeat center center;
  background-size: cover;
  height: 200px;
  text-align: center;
  overflow: hidden;
  color: white;
  text-transform: uppercase;
  font-size: 180%;
}
