@charset "utf-8";

body {
  padding-top: 80px;
}

h2 {
  text-transform: uppercase;
  font-size: 150%;
  text-align: center;
}

#main-image {
  background-color: #cfcfcf;
  background: url('/images/sayitslp-logo.png') 10% 20% no-repeat, url('/images/beachgirl.jpg') center center no-repeat;
  background-size: 30%, cover;
  max-height: 575px;
  min-height: 200px;

  @media screen and (max-width: 768px) {
    background-image: url('/images/beachgirl.jpg');
    background-size: cover;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 36.0157017%;
  }
}

#sections {
  @media screen and (max-width: 768px) {
    background: url('/images/banner-background-cropped.png') repeat-x center top rgb(202, 202, 202);

    ul {
      margin: 0;
      padding: 2em 2em 0 2em;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        display: block;
        width: 30%;
        max-width: 200px;
        min-width: 120px;
        margin: 0 5% 2em 0;
        padding: 0;
        border-radius: 5px;
        background-color: #524582;
        text-align: center;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: block;
          padding: 5px;
          color: white;
        }
      }
    }

    .info-box {
      display: none;
    }
  }

  @media screen and (min-width: 769px) {
    $sections-top: -60px;

    margin-top: 40px + -$sections-top;
    margin-bottom: 40px;
    padding-bottom: 20px;
    background: url('/images/banner-background-cropped.png') repeat-x center top rgb(202, 202, 202);

    ul {
      position: relative;
      top: $sections-top;
      max-width: 960px;
      margin: 0 auto $sections-top auto;
      padding: 0 2em;
      overflow: hidden;
      display: flex;
    }

    li {
      list-style: none;
      color: black;
      width: 17%;
      margin-right: 4%;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-right: 0;
      }

      &#about-section .section-box {
        background-color: #367bc3;

        &:hover {
          background-color: #6ba3df;
        }

        span.icon {
          background-image: url('/images/about.svg');
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -20px;
          bottom: 0;
          width: 40px;
          background-color: inherit;
          transform: skewX(4deg);
        }
      }

      &#services-section .section-box {
        background-color: #38bfa7;

        &:hover {
          background-color: #71d1c0;
        }

        span.icon {
          background-image: url('/images/services.svg');
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -20px;
          bottom: 0;
          width: 40px;
          background-color: inherit;
          transform: skewX(4deg);
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -26px;
          bottom: 0;
          width: 40px;
          background-color: inherit;
          transform: skewX(-4deg);
        }
      }

      &#resources-section .section-box {
        background-color: #8fe1a2;

        &:hover {
          background-color: #b5e9c1;
        }

        span.icon {
          background-image: url('/images/resources.svg');
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -15px;
          bottom: 0;
          width: 40px;
          background-color: inherit;
          transform: skewX(-4deg);
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -20px;
          bottom: 0;
          width: 40px;
          background-color: inherit;
          transform: skewX(4deg);
        }
      }

      &#blog-section .section-box {
        background-color: #edc1e0;

        &:hover {
          background-color: #f5dced;
        }

        span.icon {
          background-image: url('/images/blog.svg');
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -20px;
          bottom: 0;
          width: 40px;
          background-color: inherit;
          transform: skewX(4deg);
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -26px;
          bottom: 0;
          width: 40px;
          background-color: inherit;
          transform: skewX(-4deg);
        }
      }

      &#contact-section .section-box {
        background-color: #dcdcdc;

        &:hover {
          background-color: #f0f0f0;
        }

        span.icon {
          background-image: url('/images/contact.svg');
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -15px;
          bottom: 0;
          width: 40px;
          background-color: inherit;
          transform: skewX(-4deg);
        }
      }

      a.section-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        // border-radius: 10px;
        padding: 1px;

        &:hover {
          span.icon {
            transform: scale(1.04, 1.04)
          }
        }

        span.icon {
          display: block;
          width: 50%;
          margin-top: 18%;
          align-self: center;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;

          &::after {
            display: block;
            content: "";
            padding-top: 100%;
          }
        }

        span.section-heading {
          position: relative;
          z-index: 1;
          display: block;
          position: relative;
          margin: 25% 15% 15% 15%;
          padding: 5px;
          border-radius: 10px;
          background-color: #524582;
          color: white;
          text-align: center;
        }
      }
    }

    .info-box {
      flex: 1;
      margin-top: 10px;
      border-radius: 10px;
      background-color: white;
      color: black;
      text-align: center;
      font-size: 75%;
      padding: 2px 10px;

      p {
        margin: 0;
        line-height: 1.75;
      }
    }
  }
}

#help-with  {
  margin: 2.5em 0;

  li {
    margin: 0.6em 0;
  }
}

#trailer {
  position: relative;
  height: 250px;
  margin-top: 4%;
  box-sizing: border-box;
  color: #222;
  background: url('/images/quote-banner.png') center center no-repeat;
  background-size: contain;

  p {
    line-height: 1.4;
    position: absolute;
    margin: 0;
    width: 100%;
    top: 50%;
    text-align: center;
    font-size: 140%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 768px) {
    padding-top: 40px;
    font-size: 110%;
  }
}

#jana-picture {
  float: right;
}

@import "footer";