
#cta {
  overflow: hidden;
  font-size: 140%;
  margin: 4% 0;

  a {
    color: #222;
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    p {
      float: none;
      width: auto;
    }
  }

  #cta-message {
    p {
      float: left;
      width: 45%;
      line-height: 1.4;

      @media screen and (max-width: 768px) {
        float: none !important;
        width: auto;
      }
    }

    p:last-child {
      float: right;
    }

    #cta-question {
      color: #222;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }

  #cta-button-container {
    text-align: center;

    #cta-button {
      margin: 20px 0;
      display: inline-block;
      color: white;
      background-color: #367bc3;
      padding: 15px 20px;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 2px;
      font-size: 110%;
      border-radius: 15px;
    }
  }
}

#social-buttons {
  margin: 20px 0;
  text-align: center;

  a {
    display: inline-block;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    background-position: center center;
    background-size: contain;
    overflow: hidden;
    text-indent: -1000px;

    &:first-child {
      margin-left: 0;
    }
  }

  a.facebook { background-image: url('/images/icons/facebook.svg'); }
  a.twitter { background-image: url('/images/icons/twitter.svg'); }
  a.instagram { background-image: url('/images/icons/instagram.svg'); }
  a.linkedin { background-image: url('/images/icons/linkedin.svg'); }
  a.mail { background-image: url('/images/icons/email.svg'); }
}


#organizations  {
  margin-top: 8%;
  border-top: 1px solid #DEDEDE;
  padding-top: 30px;
  text-align: center;

  @media screen and (max-width: 768px) {
    ul {
      width: auto;
    }

    li {
      float: none;
      width: auto;
      margin-bottom: 20px;
    }
  }

  ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    text-align: center;
    overflow: hidden;
    max-width: 700px;
  }

  li {
    margin: 0;
    padding: 0;
    float: left;
    width: 50%;
  }

  a {
    font-size: 11px;
  }

  a.org-logo {
    display: block;
    margin: 0 35px;
  }

  img {
    display: block;
    width: 100%;
    max-width: 250px;
    margin: auto;
  }

  #organization-shbc img {
    width: 160px;
  }
}

#cshhpbc-registrant {
  text-align: center;
}

#page-footer {
  margin-top: 100px;
  margin-bottom: 40px;
  text-align: center;
  padding: 0 2em;
}
