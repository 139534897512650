@charset "utf-8";

html, body {
  margin:0;
  padding:0;
}

body {
  min-width: 320px;
  font: 16px "Muli", Helvetica, Arial, sans-serif;
  color: #555;
  line-height: 1.2;
}

article, aside, figure, footer, header, hgroup, menu, nav, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 2em;
  margin-bottom: 1em;
  color: #333;
}

p {
  line-height: 2;
}

section {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 2em;
}

a:link {color: #333;}
a:visited {color:#333;}
a:hover {color:#999;}
a:active {color:#333;}

@import "header";

@import "footer";

/* PAGES */

body.home-page {
  @import "home";
}

body.about-page {
  @import "about";
}

body.services-page {
  @import "services";
}

body.contact-page {
  @import "contact";
}

body.resources-page {
  @import "resources";
}

body.blog-page {
  @import "blog";
}

/* CONTACT PAGE */

@media screen and (max-width: 1200px) {
  body {
    font-size: 14px;
  }

  p {
    line-height: 1.7;
  }
}

@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  src: local("Muli Regular"), local("Muli-Regular"), url("https://fonts.gstatic.com/s/muli/v10/z6c3Zzm51I2zB_Gi7146Bg.woff2") format("woff2");
  unicode-range: U+0-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
