html, body {
  margin: 0;
  padding: 0;
}

body {
  min-width: 320px;
  font: 16px "Muli", Helvetica, Arial, sans-serif;
  color: #555;
  line-height: 1.2;
}

article, aside, figure, footer, header, hgroup, menu, nav, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 2em;
  margin-bottom: 1em;
  color: #333;
}

p {
  line-height: 2;
}

section {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 2em;
}

a:link {
  color: #333;
}

a:visited {
  color: #333;
}

a:hover {
  color: #999;
}

a:active {
  color: #333;
}

#covid-notice {
  background: #F4F212;
  padding: 1rem;
  text-align: center;
  font-size: 1.4rem;
}
#covid-notice strong {
  font-weight: 700;
}

#company-name {
  font-size: 140%;
  font-family: Helvetica, Arial, sans-serif;
  margin: 10px 0;
  color: #666;
  text-indent: -9999px;
  overflow: hidden;
  height: 70px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  body.home-page #company-name {
    display: none;
  }
}
#company-name a {
  display: block;
  width: 191px;
  height: 70px;
  margin: 0 auto;
  background: url("/images/sayitslp-logo.png") no-repeat center center;
  background-size: contain;
}
#company-name b {
  color: #F4F212;
}
#company-name #slp-services {
  font-size: 100%;
  font-weight: normal;
}

header {
  display: flex;
  margin-top: 10px;
  border-top: 1px solid rgba(116, 116, 116, 0.15);
  justify-content: center;
}
header #page-nav {
  display: flex;
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  width: 100%;
  max-width: 960px;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  header #page-nav {
    margin-top: 0;
    font-size: 11px;
    letter-spacing: 1px;
  }
  header #page-nav li {
    margin: 0 1.6%;
  }
}
header #page-nav li {
  flex: 0 1 auto;
  margin: 0 2%;
  padding: 0;
  list-style: none;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
}
header #page-nav a {
  display: block;
  padding: 0.75em 2px 0.5em 2px;
  text-decoration: none;
  color: #3B3B3B;
  border-bottom: 3px solid transparent;
  transition: 0.2s ease-in border-bottom-color;
  text-transform: uppercase;
  font-size: 90%;
}
header #page-nav a:hover {
  border-bottom-color: #2B2B2B;
}

h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: url("/images/banner-background-cropped.png") no-repeat center center;
  background-size: cover;
  height: 200px;
  text-align: center;
  overflow: hidden;
  color: white;
  text-transform: uppercase;
  font-size: 180%;
}

#cta {
  overflow: hidden;
  font-size: 140%;
  margin: 4% 0;
}
#cta a {
  color: #222;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  #cta p {
    float: none;
    width: auto;
  }
}
#cta #cta-message p {
  float: left;
  width: 45%;
  line-height: 1.4;
}
@media screen and (max-width: 768px) {
  #cta #cta-message p {
    float: none !important;
    width: auto;
  }
}
#cta #cta-message p:last-child {
  float: right;
}
#cta #cta-message #cta-question {
  color: #222;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}
#cta #cta-button-container {
  text-align: center;
}
#cta #cta-button-container #cta-button {
  margin: 20px 0;
  display: inline-block;
  color: white;
  background-color: #367bc3;
  padding: 15px 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 110%;
  border-radius: 15px;
}

#social-buttons {
  margin: 20px 0;
  text-align: center;
}
#social-buttons a {
  display: inline-block;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background-position: center center;
  background-size: contain;
  overflow: hidden;
  text-indent: -1000px;
}
#social-buttons a:first-child {
  margin-left: 0;
}
#social-buttons a.facebook {
  background-image: url("/images/icons/facebook.svg");
}
#social-buttons a.twitter {
  background-image: url("/images/icons/twitter.svg");
}
#social-buttons a.instagram {
  background-image: url("/images/icons/instagram.svg");
}
#social-buttons a.linkedin {
  background-image: url("/images/icons/linkedin.svg");
}
#social-buttons a.mail {
  background-image: url("/images/icons/email.svg");
}

#organizations {
  margin-top: 8%;
  border-top: 1px solid #DEDEDE;
  padding-top: 30px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  #organizations ul {
    width: auto;
  }
  #organizations li {
    float: none;
    width: auto;
    margin-bottom: 20px;
  }
}
#organizations ul {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  text-align: center;
  overflow: hidden;
  max-width: 700px;
}
#organizations li {
  margin: 0;
  padding: 0;
  float: left;
  width: 50%;
}
#organizations a {
  font-size: 11px;
}
#organizations a.org-logo {
  display: block;
  margin: 0 35px;
}
#organizations img {
  display: block;
  width: 100%;
  max-width: 250px;
  margin: auto;
}
#organizations #organization-shbc img {
  width: 160px;
}

#cshhpbc-registrant {
  text-align: center;
}

#page-footer {
  margin-top: 100px;
  margin-bottom: 40px;
  text-align: center;
  padding: 0 2em;
}

/* PAGES */
body.home-page body {
  padding-top: 80px;
}
body.home-page h2 {
  text-transform: uppercase;
  font-size: 150%;
  text-align: center;
}
body.home-page #main-image {
  background-color: #cfcfcf;
  background: url("/images/sayitslp-logo.png") 10% 20% no-repeat, url("/images/beachgirl.jpg") center center no-repeat;
  background-size: 30%, cover;
  max-height: 575px;
  min-height: 200px;
}
@media screen and (max-width: 768px) {
  body.home-page #main-image {
    background-image: url("/images/beachgirl.jpg");
    background-size: cover;
  }
}
body.home-page #main-image:before {
  content: "";
  display: block;
  padding-top: 36.0157017%;
}
@media screen and (max-width: 768px) {
  body.home-page #sections {
    background: url("/images/banner-background-cropped.png") repeat-x center top rgb(202, 202, 202);
  }
  body.home-page #sections ul {
    margin: 0;
    padding: 2em 2em 0 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  body.home-page #sections ul li {
    display: block;
    width: 30%;
    max-width: 200px;
    min-width: 120px;
    margin: 0 5% 2em 0;
    padding: 0;
    border-radius: 5px;
    background-color: #524582;
    text-align: center;
  }
  body.home-page #sections ul li:last-child {
    margin-right: 0;
  }
  body.home-page #sections ul li a {
    display: block;
    padding: 5px;
    color: white;
  }
  body.home-page #sections .info-box {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  body.home-page #sections {
    margin-top: 100px;
    margin-bottom: 40px;
    padding-bottom: 20px;
    background: url("/images/banner-background-cropped.png") repeat-x center top rgb(202, 202, 202);
  }
  body.home-page #sections ul {
    position: relative;
    top: -60px;
    max-width: 960px;
    margin: 0 auto -60px auto;
    padding: 0 2em;
    overflow: hidden;
    display: flex;
  }
  body.home-page #sections li {
    list-style: none;
    color: black;
    width: 17%;
    margin-right: 4%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  body.home-page #sections li:last-child {
    margin-right: 0;
  }
  body.home-page #sections li#about-section .section-box {
    background-color: #367bc3;
  }
  body.home-page #sections li#about-section .section-box:hover {
    background-color: #6ba3df;
  }
  body.home-page #sections li#about-section .section-box span.icon {
    background-image: url("/images/about.svg");
  }
  body.home-page #sections li#about-section .section-box::after {
    content: "";
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    width: 40px;
    background-color: inherit;
    transform: skewX(4deg);
  }
  body.home-page #sections li#services-section .section-box {
    background-color: #38bfa7;
  }
  body.home-page #sections li#services-section .section-box:hover {
    background-color: #71d1c0;
  }
  body.home-page #sections li#services-section .section-box span.icon {
    background-image: url("/images/services.svg");
  }
  body.home-page #sections li#services-section .section-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    bottom: 0;
    width: 40px;
    background-color: inherit;
    transform: skewX(4deg);
  }
  body.home-page #sections li#services-section .section-box::after {
    content: "";
    position: absolute;
    top: 0;
    right: -26px;
    bottom: 0;
    width: 40px;
    background-color: inherit;
    transform: skewX(-4deg);
  }
  body.home-page #sections li#resources-section .section-box {
    background-color: #8fe1a2;
  }
  body.home-page #sections li#resources-section .section-box:hover {
    background-color: #b5e9c1;
  }
  body.home-page #sections li#resources-section .section-box span.icon {
    background-image: url("/images/resources.svg");
  }
  body.home-page #sections li#resources-section .section-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    bottom: 0;
    width: 40px;
    background-color: inherit;
    transform: skewX(-4deg);
  }
  body.home-page #sections li#resources-section .section-box::after {
    content: "";
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    width: 40px;
    background-color: inherit;
    transform: skewX(4deg);
  }
  body.home-page #sections li#blog-section .section-box {
    background-color: #edc1e0;
  }
  body.home-page #sections li#blog-section .section-box:hover {
    background-color: #f5dced;
  }
  body.home-page #sections li#blog-section .section-box span.icon {
    background-image: url("/images/blog.svg");
  }
  body.home-page #sections li#blog-section .section-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    bottom: 0;
    width: 40px;
    background-color: inherit;
    transform: skewX(4deg);
  }
  body.home-page #sections li#blog-section .section-box::after {
    content: "";
    position: absolute;
    top: 0;
    right: -26px;
    bottom: 0;
    width: 40px;
    background-color: inherit;
    transform: skewX(-4deg);
  }
  body.home-page #sections li#contact-section .section-box {
    background-color: #dcdcdc;
  }
  body.home-page #sections li#contact-section .section-box:hover {
    background-color: #f0f0f0;
  }
  body.home-page #sections li#contact-section .section-box span.icon {
    background-image: url("/images/contact.svg");
  }
  body.home-page #sections li#contact-section .section-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    bottom: 0;
    width: 40px;
    background-color: inherit;
    transform: skewX(-4deg);
  }
  body.home-page #sections li a.section-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: 1px;
  }
  body.home-page #sections li a.section-box:hover span.icon {
    transform: scale(1.04, 1.04);
  }
  body.home-page #sections li a.section-box span.icon {
    display: block;
    width: 50%;
    margin-top: 18%;
    align-self: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  body.home-page #sections li a.section-box span.icon::after {
    display: block;
    content: "";
    padding-top: 100%;
  }
  body.home-page #sections li a.section-box span.section-heading {
    position: relative;
    z-index: 1;
    display: block;
    position: relative;
    margin: 25% 15% 15% 15%;
    padding: 5px;
    border-radius: 10px;
    background-color: #524582;
    color: white;
    text-align: center;
  }
  body.home-page #sections .info-box {
    flex: 1;
    margin-top: 10px;
    border-radius: 10px;
    background-color: white;
    color: black;
    text-align: center;
    font-size: 75%;
    padding: 2px 10px;
  }
  body.home-page #sections .info-box p {
    margin: 0;
    line-height: 1.75;
  }
}
body.home-page #help-with {
  margin: 2.5em 0;
}
body.home-page #help-with li {
  margin: 0.6em 0;
}
body.home-page #trailer {
  position: relative;
  height: 250px;
  margin-top: 4%;
  box-sizing: border-box;
  color: #222;
  background: url("/images/quote-banner.png") center center no-repeat;
  background-size: contain;
}
body.home-page #trailer p {
  line-height: 1.4;
  position: absolute;
  margin: 0;
  width: 100%;
  top: 50%;
  text-align: center;
  font-size: 140%;
  transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  body.home-page #trailer {
    padding-top: 40px;
    font-size: 110%;
  }
}
body.home-page #jana-picture {
  float: right;
}
body.home-page #cta {
  overflow: hidden;
  font-size: 140%;
  margin: 4% 0;
}
body.home-page #cta a {
  color: #222;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  body.home-page #cta p {
    float: none;
    width: auto;
  }
}
body.home-page #cta #cta-message p {
  float: left;
  width: 45%;
  line-height: 1.4;
}
@media screen and (max-width: 768px) {
  body.home-page #cta #cta-message p {
    float: none !important;
    width: auto;
  }
}
body.home-page #cta #cta-message p:last-child {
  float: right;
}
body.home-page #cta #cta-message #cta-question {
  color: #222;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}
body.home-page #cta #cta-button-container {
  text-align: center;
}
body.home-page #cta #cta-button-container #cta-button {
  margin: 20px 0;
  display: inline-block;
  color: white;
  background-color: #367bc3;
  padding: 15px 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 110%;
  border-radius: 15px;
}
body.home-page #social-buttons {
  margin: 20px 0;
  text-align: center;
}
body.home-page #social-buttons a {
  display: inline-block;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background-position: center center;
  background-size: contain;
  overflow: hidden;
  text-indent: -1000px;
}
body.home-page #social-buttons a:first-child {
  margin-left: 0;
}
body.home-page #social-buttons a.facebook {
  background-image: url("/images/icons/facebook.svg");
}
body.home-page #social-buttons a.twitter {
  background-image: url("/images/icons/twitter.svg");
}
body.home-page #social-buttons a.instagram {
  background-image: url("/images/icons/instagram.svg");
}
body.home-page #social-buttons a.linkedin {
  background-image: url("/images/icons/linkedin.svg");
}
body.home-page #social-buttons a.mail {
  background-image: url("/images/icons/email.svg");
}
body.home-page #organizations {
  margin-top: 8%;
  border-top: 1px solid #DEDEDE;
  padding-top: 30px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  body.home-page #organizations ul {
    width: auto;
  }
  body.home-page #organizations li {
    float: none;
    width: auto;
    margin-bottom: 20px;
  }
}
body.home-page #organizations ul {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  text-align: center;
  overflow: hidden;
  max-width: 700px;
}
body.home-page #organizations li {
  margin: 0;
  padding: 0;
  float: left;
  width: 50%;
}
body.home-page #organizations a {
  font-size: 11px;
}
body.home-page #organizations a.org-logo {
  display: block;
  margin: 0 35px;
}
body.home-page #organizations img {
  display: block;
  width: 100%;
  max-width: 250px;
  margin: auto;
}
body.home-page #organizations #organization-shbc img {
  width: 160px;
}
body.home-page #cshhpbc-registrant {
  text-align: center;
}
body.home-page #page-footer {
  margin-top: 100px;
  margin-bottom: 40px;
  text-align: center;
  padding: 0 2em;
}

body.about-page #pic {
  float: left;
  margin: 0 20px 20px 0;
  min-width: 190px;
  max-width: 285px;
  width: 45%;
  height: auto;
}
body.about-page #fb-feed {
  padding-top: 2%;
  text-align: center;
  min-height: 2000px;
}
body.about-page h2 {
  font-weight: normal;
  color: inherit;
  font-size: 1em;
}
body.about-page h2 b {
  font-size: 1.5em;
}
body.about-page #info section {
  padding: 2%;
}
@media screen and (min-width: 980px) {
  body.about-page #info section {
    border-radius: 20px;
  }
}
body.about-page #info section:nth-child(even) {
  background-color: #f5f3f5;
  text-align: right;
}
body.about-page #info section:nth-child(even) span.icon {
  float: right;
  margin-left: 2%;
  margin-right: 0;
}
body.about-page #info section p:last-child {
  margin-bottom: 0;
}
body.about-page #info section .icon {
  float: left;
  display: block;
  border-radius: 50%;
  padding: 16px;
  margin-right: 2%;
  margin-bottom: 2%;
}
body.about-page #info section#practice .icon {
  background-color: #8fe1a2;
}
body.about-page #info section#clients .icon {
  background-color: #38bfa7;
}
body.about-page #info section#approach .icon {
  background-color: #367bc3;
}
body.about-page #info section#focus .icon {
  background-color: #edc1e0;
}
body.about-page #info section#experience .icon {
  background-color: #d9d9d9;
}
body.about-page #info section#neurodivergent .icon {
  background-color: #8fe1a2;
}
body.about-page #info section#availability .icon {
  background-color: #38bfa7;
}

body.services-page main section {
  padding: 2%;
}
@media screen and (min-width: 980px) {
  body.services-page main section {
    border-radius: 20px;
  }
}
body.services-page main section img {
  float: left;
  margin: 0 2% 0 0;
  width: 175px;
  height: 220px;
  border-radius: 20px;
}
@media screen and (max-width: 768px) {
  body.services-page main section img {
    display: none;
  }
}
body.services-page main section:nth-child(odd) {
  background-color: #f5f3f5;
  text-align: right;
}
body.services-page main section:nth-child(odd) img {
  float: right;
  margin: 0 0 0 2%;
}
body.services-page main section h2 {
  margin: 0;
  background-color: #8fe1a2;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 15px;
  min-width: 200px;
  font-weight: bold;
  text-align: center;
}
body.services-page main section p {
  line-height: 1.4;
}

body.contact-page #contact-section {
  display: flex;
  margin-top: 4%;
}
@media screen and (max-width: 768px) {
  body.contact-page #contact-section {
    flex-direction: column;
  }
}
body.contact-page #contact-section h2, body.contact-page #contact-section h3 {
  margin-top: 0;
  font-size: 1.5em;
}
body.contact-page #contact-section .row {
  margin-bottom: 3%;
}
body.contact-page #contact-section ul, body.contact-page #contact-section ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
body.contact-page #contact-section li {
  margin-bottom: 3%;
}
body.contact-page #contact-info {
  flex: 1;
}
body.contact-page #jana-picture {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/images/jana-round.png") no-repeat center center;
  background-size: 50%;
}
@media screen and (max-width: 768px) {
  body.contact-page #jana-picture {
    display: none;
  }
}
body.contact-page #areas-availability {
  flex: 1;
}
body.contact-page #areas-served ul {
  columns: 2;
}
@media screen and (max-width: 480px) {
  body.contact-page #areas-served ul {
    columns: 1;
  }
}

body.resources-page main section {
  padding: 2%;
}
body.resources-page #videos-section h2 {
  margin-top: 0;
  font-size: 2rem;
}
body.resources-page #videos-section ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
@media screen and (max-width: 768px) {
  body.resources-page #videos-section ul {
    flex-direction: column;
  }
}
body.resources-page #videos-section li {
  flex: 1;
  margin-right: 3%;
}
body.resources-page #videos-section li:last-child {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  body.resources-page #videos-section li {
    max-width: 250px;
    margin-right: 0;
    margin-bottom: 3%;
  }
}
body.resources-page #videos-section iframe {
  border: none;
}
@media screen and (max-width: 768px) {
  body.resources-page #links-section {
    flex-direction: column;
  }
}
body.resources-page #links-section h2 {
  margin-top: 0;
  font-size: 2rem;
}
body.resources-page #links-section h3 {
  margin-top: 10%;
  margin-bottom: 2%;
  font-size: 1.25em;
}
body.resources-page #links-section ul, body.resources-page #links-section li {
  margin: 0;
  padding: 0;
  list-style: none;
}
body.resources-page #links-section li {
  margin-bottom: 3%;
}
body.resources-page #links-section #links {
  display: flex;
}
body.resources-page #links-section .column {
  flex: 1;
  margin-right: 3%;
}
body.resources-page #links-section .column *:first-child h3 {
  margin-top: 0;
}
body.resources-page #links-section .column:last-child {
  margin-right: 0;
}
body.resources-page #downloads-section h2 {
  margin-top: 0;
  font-size: 2rem;
}
body.resources-page #downloads-section ul, body.resources-page #downloads-section ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
body.resources-page #downloads-section ul {
  display: flex;
}
@media screen and (max-width: 768px) {
  body.resources-page #downloads-section ul {
    flex-direction: column;
  }
}
body.resources-page #downloads-section li {
  flex: 1;
  margin-right: 3%;
}
body.resources-page #downloads-section li:last-child {
  margin-right: 0;
}
body.resources-page #downloads-section li img {
  max-width: 100%;
}
body.resources-page #downloads-section li a.download-cta {
  display: block;
  margin-top: 15px;
  border: 1px solid #bbb;
  padding: 10px;
  text-decoration: none;
  text-align: center;
}

body.blog-page #blog-section section {
  margin-bottom: 3em;
}
body.blog-page #blog-section section h2 {
  display: flex;
  justify-content: space-between;
}
body.blog-page #blog-section section h2 time {
  font-size: 0.75em;
  font-weight: normal;
}
body.blog-page #blog-section section:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
body.blog-page #blog-section img {
  display: block;
  width: 100%;
  max-width: 400px;
  float: right;
  margin: 0 0 2em 2em;
}
body.blog-page #blog-section img + img {
  clear: right;
}
body.blog-page #blog-section .image-row {
  display: flex;
}
body.blog-page #blog-section .image-row img {
  flex: 1;
  margin-left: 10px;
}
body.blog-page #blog-section .image-row img:first-of-type {
  margin-left: 0;
}
body.blog-page #blog-section .twitter-tweet {
  float: right;
  clear: right;
}
body.blog-page #blog-section .youtube-video {
  display: block;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  body.blog-page #blog-section img {
    float: none;
    margin: 10px auto;
  }
}
body.blog-page #blog-years {
  display: flex;
}
body.blog-page #blog-years ol, body.blog-page #blog-years li {
  list-style: none;
  margin: 0;
  padding: 0;
}
body.blog-page #blog-years ol {
  margin-left: 1%;
  flex: 1;
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
}

/* CONTACT PAGE */
@media screen and (max-width: 1200px) {
  body {
    font-size: 14px;
  }

  p {
    line-height: 1.7;
  }
}
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  src: local("Muli Regular"), local("Muli-Regular"), url("https://fonts.gstatic.com/s/muli/v10/z6c3Zzm51I2zB_Gi7146Bg.woff2") format("woff2");
  unicode-range: U+0-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}