main section {
  padding: 2%;
}

#videos-section {
  h2 {
    margin-top: 0;
    font-size: 2rem;
  }

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  li {
    flex: 1;
    margin-right: 3%;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 768px) {
      max-width: 250px;
      margin-right: 0;
      margin-bottom: 3%;
    }
  }

  iframe {
    border: none;
  }
}

#links-section {
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  h2 {
    margin-top: 0;
    font-size: 2rem;
  }

  h3 {
    margin-top: 10%;
    margin-bottom: 2%;
    font-size: 1.25em;
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 3%;
  }

  #links {
    display: flex;
  }

  .column {
    flex: 1;
    margin-right: 3%;

    *:first-child h3 {
      margin-top: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

#downloads-section {
  h2 {
    margin-top: 0;
    font-size: 2rem;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  li {
    flex: 1;
    margin-right: 3%;

    &:last-child {
      margin-right: 0;
    }

    img {
      max-width: 100%;
    }

    a.download-cta {
      display: block;
      margin-top: 15px;
      border: 1px solid #bbb;
      padding: 10px;
      text-decoration: none;
      text-align: center;
    }
  }
}
