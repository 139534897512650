#contact-section {
  display: flex;
  margin-top: 4%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  h2, h3 {
    margin-top: 0;
    font-size: 1.5em;
  }

  .row {
    margin-bottom: 3%;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 3%;
  }
}

#contact-info {
  flex: 1;
}

#jana-picture {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('/images/jana-round.png') no-repeat center center;
  background-size: 50%;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

#areas-availability {
  flex: 1;
}

// #vic-map {
//   margin-top: 4%;
//   float: right;
//   width: 45%;
//   display: block;

//   @media screen and (max-width: 768px) {
//     float: none;
//     width: auto;
//     margin: 4% auto;
//   }

//   img {
//     display: block;
//     width: 100%;
//   }
// }


#areas-served ul {
  columns: 2;

  @media screen and (max-width: 480px) {
    columns: 1;
  }
}
