#pic {
  float: left;
  margin: 0 20px 20px 0;
  min-width: 190px;
  max-width: 285px;
  width: 45%;
  height: auto;
}

#fb-feed {
  padding-top: 2%;
  text-align: center;
  min-height: 2000px;
}

h2 {
  font-weight: normal;
  color: inherit;
  font-size: 1em;
}

h2 b {
  font-size: 1.5em;
}

#info section {
  padding: 2%;
  
  @media screen and (min-width: 980px) {
    border-radius: 20px;    
  }

  &:nth-child(even) {
    background-color: #f5f3f5;
    text-align: right;

    span.icon {
      float: right;
      margin-left: 2%;
      margin-right: 0;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  .icon {
    float: left;
    display: block;
    border-radius: 50%;
    padding: 16px;
    margin-right: 2%;
    margin-bottom: 2%
  }

  &#practice .icon {
    background-color: #8fe1a2;
  }

  &#clients .icon {
    background-color: #38bfa7;
  }

  &#approach .icon {
    background-color: #367bc3;
  }

  &#focus .icon {
    background-color: #edc1e0;
  }

  &#experience .icon {
    background-color: #d9d9d9ff;
  }

  &#neurodivergent .icon {
    background-color: #8fe1a2;
  }

  &#availability .icon {
    background-color: #38bfa7;
  }
}